.heading_one {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    color: black;
    padding-top: 20pt;
}


.heading_two {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #470943ed;
    padding-top: 20pt;
}

body {
    background-color: rgb(247, 247, 247);
}

