h2 {
    font-size: 18px;
}

p.para_label {
    font-weight: 600;
    color: black;
}

.myProfile {
    position:fixed;
    right: 50px;
    bottom: 50px; 
}